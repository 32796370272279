<template>
  <div class="backgroud-img">
    <div class="sticky top-0 z-10 mb-12">
      <stepper :current-step="1"/>
      <step-progress-bar :step="1"/>
    </div>
    <ValidationObserver
      ref="form"
      v-slot="{ invalid, handleSubmit }"
    >
      <form
        class="flex flex-col mx-12"
        @submit.prevent="handleSubmit(nextStep)"
      >
        <div class="flex items-center">
          <toggle-button
            @change="companyToggleChange"
            :value="isCompanyLead"
            :sync="true"
            color="#4066B3"
            :height="32"
            :width="56"
            class="mr-5"
          />
          <label
            @click="companyToggleChange"
            class="text-sm uppercase"
          >
            Activar si el propietario del vehículo es una empresa
          </label>
        </div>
        <div class="flex flex-row my-12">
          <div class="flex-none mt-2 mr-8">
            <inline-svg
              :src="require('../assets/images/icons/user.svg')"
              class="text-jooycar-gray"
            />
          </div>
          <div class="flex flex-col">
            <div class="text-2xl font-bold text-jooycar-blue-dark">
              Datos de propietario
            </div>
            <div class="text-sm">
              El asegurado debe ser el dueño del vehículo
            </div>
          </div>
        </div>
        <div class="flex flex-col flex-wrap md:flex-row">
          <data-input
            class="flex flex-col flex-1 mt-3 md:pr-4"
            label="Rut*"
            name="idValue"
            placeholder="11.111.111-1"
            v-model="insuredPerson.idValue"
            :rut="true"
            rules="required|rut"
            :max-length="12"
          />
          <data-input
            class="flex flex-col flex-1 mt-3 md:pr-4"
            label="Nombre*"
            name="name"
            placeholder="Juan"
            v-model="insuredPerson.firstName"
            rules="required"
          />
          <data-input
            class="flex flex-col flex-1 mt-3 md:pr-4"
            label="Apellido Paterno*"
            name="firstLastName"
            placeholder="Pérez"
            v-model="insuredPerson.lastName1"
            :disabled="isCompanyLead"
            :rules="{ required_if: !isCompanyLead }"
          />
          <data-input
            class="flex flex-col flex-1 mt-3"
            label="Apellido Materno*"
            name="secondLastName"
            placeholder="Soto"
            v-model="insuredPerson.lastName2"
            :disabled="isCompanyLead"
            :rules="{ required_if: !isCompanyLead }"
          />
        </div>
        <div class="flex flex-col md:flex-row">
          <data-select
            class="flex flex-col flex-1 mt-3 md:pr-4"
            label="Género*"
            name="gender"
            v-model="insuredPerson.gender"
            placeholder="Seleccionar"
            :options="genders"
            :disabled="isCompanyLead"
            :rules="{ required_if: !isCompanyLead }"
          />
          <data-input
            class="flex flex-col flex-1 mt-3 md:pr-4"
            label="Teléfono Móvil*"
            name="mobile"
            placeholder="12345678"
            v-model="insuredPerson.mobile"
            rules="digits:8"
            text-left="+569"
            :process="value => value.replace(/\D/g,'')"
            :max-length="8"
          />
          <data-date
            class="flex flex-col flex-1 mt-3"
            label="Fecha de nacimiento*"
            name="birthDate"
            placeholder="DD/MM/AAAA"
            v-model="insuredPerson.birthDate"
            :max-date="new Date()"
            :disabled="isCompanyLead"
            :rules="{ required_if: !isCompanyLead }"
            :dateColor="isCompanyLead ? 'transparent-text' : ''"
          />
        </div>
        <div class="flex flex-col md:flex-row md:mt-4">
          <data-input
            class="flex flex-col flex-1 mt-3 md:mt-0 md:pr-4"
            label="Email*"
            name="mail"
            placeholder="juanitop@gmail.com"
            v-model="insuredPerson.email"
            rules="required|email"
          />
          <data-select
            class="flex flex-col flex-1 mt-3 md:mt-0"
            label="Comuna*"
            name="municipality"
            placeholder="Seleccionar"
            v-model="municipality"
            :options="municipalityOptions"
            rules="required"
          />
        </div>
        <div class="flex flex-row my-12">
          <div class="flex-none mt-2 mr-8">
            <inline-svg
              :src="require('../assets/images/icons/car.svg')"
              class="text-jooycar-gray"
            />
          </div>
          <div class="flex flex-col">
            <div class="text-2xl font-bold text-jooycar-blue-dark">
              Datos de Vehículo
            </div>
            <div class="text-sm">
              Seguro de autos sólo válido para vehículos de uso particular
            </div>
          </div>
        </div>
        <div class="flex items-center">
          <input
            id="isNew"
            type="checkbox"
            v-model="isNew"
            class="w-4 bg-jooycar-gray-lightest"
          >
          <label
            for="isNew"
            class="inline-block ml-4 text-sm font-medium text-jooycar-gray-dark"
          >
            ¿El vehículo es nuevo? (sin patente)
          </label>
        </div>
        <div class="flex flex-col mt-3 md:flex-row">
          <div class="flex flex-col flex-1 md:pr-4">
            <data-input
              label="Patente*"
              name="licensePlate"
              placeholder="BBBB10"
              :disabled="insuredVehicle.isNew === '1'"
              v-model="insuredVehicle.plate"
              :rules="insuredVehicle.isNew === '1' ? '' : 'required|plate'"
              :process="value => value.toUpperCase()"
              :max-length="6"
            />
          </div>
          <data-select
            class="flex flex-col flex-1 mt-3 md:mt-0 md:pr-4"
            label="Marca*"
            name="brand"
            placeholder="Seleccionar"
            v-model="insuredVehicle.brandCode"
            :options="brandsForSelect"
            rules="required"
          />
          <data-select
            class="flex flex-col flex-1 mt-3 md:mt-0 md:pr-4"
            label="Modelo*"
            name="model"
            placeholder="Seleccionar"
            v-model="insuredVehicle.modelCode"
            :options="modelOptions"
            rules="required"
          />
          <data-select
            class="flex flex-col flex-1 mt-3 md:mt-0"
            label="Año*"
            name="year"
            placeholder="Seleccionar"
            v-model="insuredVehicle.year"
            :options="years"
            rules="required"
          />
        </div>
        <div class="flex justify-center mt-10 text-sm text-jooycar-gray-dark">
          <p>* Campos obligatorios</p>
        </div>
        <div class="flex justify-center my-6">
          <button
            v-if="validChannel"
            id="submitBtn"
            class="w-auto px-16 py-3 font-semibold text-white bg-jooycar-blue"
            :class="{
              'cursor-default opacity-50': invalid,
              'hover:bg-jooycar-blue-dark': !invalid
            }"
            :disabled="invalid"
          >
            COTIZAR
          </button>
        </div>
      </form>
    </ValidationObserver>
    <restart-form-prompt
      v-if="leadError"
      class="mb-8"
      @restart-form="restartData"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { ToggleButton } from 'vue-js-toggle-button';
import DataDate from '../components/data-date.vue';
import DataInput from '../components/data-input.vue';
import DataSelect from '../components/data-select.vue';
import Stepper from '../components/stepper';
import StepProgressBar from '../components/step-progress-bar.vue';
import RestartFormPrompt from '../components/restart-form-prompt';
import dateFormatter from '../helpers/dateFormatter';


const NUMBER_OF_YEARS = 16;
const CHILEAN_MOBILE_CODE = '9';
const DEFAULT_CHANNEL = 'chilena';
const VALID_CHANNELS = [
  DEFAULT_CHANNEL,
  'chil_funcionario',
  'chil_santander',
  'chil_corredor'
];

export default {
  components: {
    DataDate,
    DataInput,
    DataSelect,
    Stepper,
    RestartFormPrompt,
    ToggleButton,
    StepProgressBar,
  },
  data() {
    return {
      insuredPerson: {},
      insuredAddress: {},
      insuredVehicle: {},
      genders: [
        {
          label: 'Masculino',
          value: 'M',
        },
        {
          label: 'Femenino',
          value: 'F',
        },
      ],
      modelOptions: [],
      isCompanyLead: false,
    };
  },
  computed: {
    ...mapState(['leadId', 'municipalities', 'models', 'lead', 'leadError']),
    ...mapState({
      stages: state => state.lead.stages,
    }),
    ...mapGetters(['brandsForSelect']),
    years() {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth();
      const yearsLength = this.isNew ? 1 : NUMBER_OF_YEARS;
      const years = (new Array(yearsLength).fill(0)).map((_value, index) => (
        { label: currentYear - index, value: currentYear - index }
      ));
      // eslint-disable-next-line no-magic-numbers
      if (currentMonth >= 8) {
        years.unshift({ label: currentYear + 1, value: currentYear + 1 });
      }

      return years;
    },
    selectedBrandCode() {
      return this.insuredVehicle.brandCode;
    },
    selectedBrand() {
      return this.brandsForSelect.find(brand => brand.value === this.insuredVehicle.brandCode);
    },
    selectedModel() {
      return this.modelOptions.find(model => model.value === this.insuredVehicle.modelCode);
    },
    selectedMunicipality() {
      return this.municipalities.find(municipality => municipality.id === this.insuredAddress.municipality);
    },
    formattedBirthDate() {
      const date = this.insuredPerson.birthDate;

      return dateFormatter(date, '-', true);
    },
    municipality: {
      get() {
        return this.insuredAddress.municipality;
      },
      set(value) {
        this.insuredAddress.municipality = value;
        this.insuredAddress.state = this.selectedMunicipality.stateId;
      },
    },
    municipalityOptions() {
      return this.toSelectOptions(this.municipalities);
    },
    leadToUpdate() {
      return {
        ...this.lead,
        currentStage: 'Quote',
        stages: {
          ...this.stages,
          insuredPerson: {
            ...this.insuredPerson,
            birthDate: this.formattedBirthDate,
          },
          insuredVehicle: {
            ...this.insuredVehicle,
            displayBrand: this.selectedBrand.label,
            displayModel: this.selectedModel.label,
          },
          insuredAddress: this.insuredAddress,
        },
      };
    },
    isNew: {
      get() {
        return this.insuredVehicle.isNew === '1';
      },
      set(value) {
        this.insuredVehicle.isNew = value ? '1' : '0';
        this.insuredVehicle.plate = '';
        if (!this.years.find(year => year.value === this.insuredVehicle.year)) {
          this.insuredVehicle.year = '';
        }
      },
    },
    validChannel() {
      return !this.$route.query.channel || VALID_CHANNELS.includes(this.$route.query.channel);
    },
    queryChannel() {
      return this.$route.query.channel || DEFAULT_CHANNEL;
    },
  },
  methods: {
    nextStep() {
      if (this.validChannel) {
        this.$analytics.logEvent(
          `${this.$analyticsEvents.custom.nextStage.eventName}_2`,
          {
            name: this.$analyticsEvents.stages.quote
          }
        );
        this.$store.dispatch('updateLeadAndRedirect', { leadToUpdate: this.leadToUpdate, routeName: 'Quote' });
      }
    },
    toSelectOptions(list) {
      return list.map((item) => ({ label: item.name, value: item.id }));
    },
    companyToggleChange() {
      this.isCompanyLead = !this.isCompanyLead;
      if (this.isCompanyLead) {
        const { gender, lastName1, lastName2 } = this.insuredPerson;
        this.savedCompanyToggleData = {
          gender, lastName1, lastName2,
        };
        this.insuredPerson.gender = 'E';
        this.insuredPerson.lastName1 = '-';
        this.insuredPerson.lastName2 = '-';
        this.insuredPerson.birthDate = new Date('01-01-1900')
      } else {
        const { gender, lastName1, lastName2 } = this.savedCompanyToggleData;
        this.insuredPerson = {
          ...this.insuredPerson, gender, lastName1, lastName2,
          birthDate: new Date(),
        };
      }
    },
    restartData() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$store.dispatch('createLead', this.$route.query.channel || this.lead.channel);
      this.$store.dispatch('getMunicipalities');
      this.$store.dispatch('getBrands');
    },
  },
  mounted() {
    if (this.municipalityOptions.length === 0) {
      this.$store.dispatch('getMunicipalities');
    }
    if (this.brandsForSelect.length === 0) {
      this.$store.dispatch('getBrands');
    }
  },
  watch: {
    stages: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.insuredPerson = { ...newVal.insuredPerson, mobileCode: CHILEAN_MOBILE_CODE };
          this.insuredAddress = { ...newVal.insuredAddress };
          this.insuredVehicle = { ...newVal.insuredVehicle };
          if (this.insuredPerson.birthDate) {
            this.insuredPerson.birthDate = new Date(this.insuredPerson.birthDate.replaceAll('-', '/'));
          }
          this.isCompanyLead = newVal.insuredPerson.gender === 'E';
        }
      },
    },
    selectedBrandCode: {
      immediate: true,
      async handler(newValue, oldValue) {
        if (!newValue) return;
        if (oldValue) this.insuredVehicle.model = '';

        if (!this.models[newValue]) {
          await this.$store.dispatch('getModels', newValue);
        }
        this.modelOptions = this.toSelectOptions(this.models[newValue]);
      },
    },
  },
};
</script>

<style lang="scss">
  .transparent-text {
    color: transparent;
  }
</style>
